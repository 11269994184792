import createReducer from "@/ReUtil/utils/createReducer";
import webConfig from "../../web.config";

const initState = {
	sidebar: { show: false },
	keywords: webConfig.keywords,
	user: null,
	access_token: null,
	version: null
};

const actionHandlers = {
	SET_USER: (state, action) => {
		const result = { ...state };
		result.user = action.payload.user;
		result.access_token = action.payload.access_token;
		return result;
	},
	SET_KEYWORDS: (state, action) => {
		const result = { ...state };
		result.keywords = action.payload.keywords ? action.payload.keywords : webConfig.keywords;
		return result;
	},
	SET_APP_VERSION: (state, action) => {
		const result = { ...state };
		result.version = action.payload.version;
		return result;
	}
};

export default createReducer(initState, actionHandlers);
